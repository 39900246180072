var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('b-form',[_c('EKDialog',{ref:"bankDialog",attrs:{"title":!_vm.id ? 'إضافة سؤال كتابي' : 'تعديل السؤال الكتابي',"endClose":""},on:{"open":function($event){return _vm.$store.commit('Reset_Interviews_Dto', _vm.id)},"ok":_vm.onSubmit,"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('EKInputText',{attrs:{"rules":[
                        {
                            type: 'required',
                            message: 'اسم السؤال الكتابي إجباري'
                        }
                    ],"label":"اسم السؤال الكتابي","placeholder":"ادخل اسم السؤال الكتابي","name":"name"},model:{value:(_vm.interviewsDto.name),callback:function ($$v) {_vm.$set(_vm.interviewsDto, "name", $$v)},expression:"interviewsDto.name"}}),_c('EKInputSelect',{attrs:{"label":"سنة السؤال الكتابي","placeholder":"ادخل سنة السؤال الكتابي","rules":[
                        {
                            type: 'required',
                            message: 'سنة السؤال الكتابي إجباري'
                        }
                    ],"options":_vm.years,"name":"years"},model:{value:(_vm.interviewsDto.year),callback:function ($$v) {_vm.$set(_vm.interviewsDto, "year", $$v)},expression:"interviewsDto.year"}}),_c('EKInputSelect',{attrs:{"label":"المادة","placeholder":"اختر المادة","rules":[
                        {
                            type: 'required',
                            message: 'المادة إجبارية'
                        }
                    ],"options":_vm.subjectsList,"name":"subjectId"},model:{value:(_vm.interviewsDto.subjectId),callback:function ($$v) {_vm.$set(_vm.interviewsDto, "subjectId", $$v)},expression:"interviewsDto.subjectId"}}),_c('EKInputSelect',{attrs:{"label":"تصنيفات السؤال الكتابي","placeholder":"اختر تصنيفات","rules":[
                        {
                            type: 'required',
                            message:
                                'اختر التصنيفات التي يكون السؤال الكتابي تابع لها'
                        }
                    ],"multiple":"","options":_vm.tagsList,"name":"categories"},model:{value:(_vm.interviewsDto.tagIds),callback:function ($$v) {_vm.$set(_vm.interviewsDto, "tagIds", $$v)},expression:"interviewsDto.tagIds"}})]},proxy:true}])},[_c('template',{slot:"activator"},[_c('activaitor',{attrs:{"placeholder":"ابحث عن سؤال كتابي محدد"},on:{"search":_vm.search}},[_c('b-button',{staticClass:"text-nowrap",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.open()}}},[_vm._v(" "+_vm._s(!_vm.id ? "سؤال كتابي جديد" : "تعديل السؤال الكتابي")+" "),(!_vm.id)?_c('unicon',{staticClass:"ml-1",attrs:{"name":"plus","width":"18","height":"18","fill":"#fff"}}):_vm._e()],1),(_vm.id)?_c('b-button',{staticClass:"text-nowrap ml-2",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.goToAddQuestion}},[_vm._v(" سؤال جديد "),_c('unicon',{staticClass:"ml-1",attrs:{"name":"plus","width":"18","height":"18","fill":"#fff"}})],1):_vm._e()],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }