<template>
    <ValidationObserver ref="observer">
        <b-form>
            <EKDialog
                :title="!id ? 'إضافة سؤال كتابي' : 'تعديل السؤال الكتابي'"
                ref="bankDialog"
                @open="$store.commit('Reset_Interviews_Dto', id)"
                @ok="onSubmit"
                @search="search"
                endClose
            >
                <template slot="activator">
                    <activaitor
                        @search="search"
                        placeholder="ابحث عن سؤال كتابي محدد"
                    >
                        <b-button
                            size="sm"
                            variant="primary"
                            class="text-nowrap"
                            @click="open()"
                        >
                            {{
                                !id ? "سؤال كتابي جديد" : "تعديل السؤال الكتابي"
                            }}
                            <unicon
                                v-if="!id"
                                class="ml-1"
                                name="plus"
                                width="18"
                                height="18"
                                fill="#fff"
                            />
                        </b-button>
                        <b-button
                            v-if="id"
                            size="sm"
                            variant="primary"
                            class="text-nowrap ml-2"
                            @click="goToAddQuestion"
                        >
                            سؤال جديد
                            <unicon
                                class="ml-1"
                                name="plus"
                                width="18"
                                height="18"
                                fill="#fff"
                            />
                        </b-button>
                    </activaitor>
                </template>
                <template #body>
                    <EKInputText
                        :rules="[
                            {
                                type: 'required',
                                message: 'اسم السؤال الكتابي إجباري'
                            }
                        ]"
                        label="اسم السؤال الكتابي"
                        v-model="interviewsDto.name"
                        placeholder="ادخل اسم السؤال الكتابي"
                        name="name"
                    />
                    <EKInputSelect
                        v-model="interviewsDto.year"
                        label="سنة السؤال الكتابي"
                        placeholder="ادخل سنة السؤال الكتابي"
                        :rules="[
                            {
                                type: 'required',
                                message: 'سنة السؤال الكتابي إجباري'
                            }
                        ]"
                        :options="years"
                        name="years"
                    />
                    <EKInputSelect
                        label="المادة"
                        placeholder="اختر المادة"
                        :rules="[
                            {
                                type: 'required',
                                message: 'المادة إجبارية'
                            }
                        ]"
                        :options="subjectsList"
                        v-model="interviewsDto.subjectId"
                        name="subjectId"
                    />
                    <EKInputSelect
                        label="تصنيفات السؤال الكتابي"
                        placeholder="اختر تصنيفات"
                        :rules="[
                            {
                                type: 'required',
                                message:
                                    'اختر التصنيفات التي يكون السؤال الكتابي تابع لها'
                            }
                        ]"
                        multiple
                        :options="tagsList"
                        v-model="interviewsDto.tagIds"
                        name="categories"
                    />
                </template>
            </EKDialog>
        </b-form>
    </ValidationObserver>
</template>
<script>
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import activaitor from "@Ekcore/components/EK-dialog/activaitor";
import { mapState, mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
export default {
    components: {
        EKDialog,
        EKInputText,
        EKInputSelect,
        ValidationObserver,
        activaitor
    },
    props: {
        id: String
    },
    computed: {
        ...mapGetters(["years", "tagsList"]),
        ...mapState({
            interviewsDto: state => state.interviews.interviewsDto,
            interviewQuestionList: state =>
                state.interviews.interviewQuestionList,
            subjectsList: state => state.subjects.subjectsList
        })
    },
    created() {
        this.fetchTotalTag();
        this.fetchSubject({ semesterId: "", year: "", facultyId: "" });
    },
    methods: {
        ...mapActions([
            "fetchSubject",
            "fetchTotalTag",
            "addInterview",
            "updateInterview"
        ]),
        onSubmit() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    if (!this.interviewsDto.id) {
                        this.addInterview({
                            name: this.interviewsDto.name,
                            year: this.interviewsDto.year,
                            type: this.interviewsDto.type,
                            subjectId: this.interviewsDto.subjectId,
                            tagIds: this.interviewsDto.tagIds
                        });
                    } else {
                        this.updateInterview({
                            id: this.interviewsDto.id,
                            name: this.interviewsDto.name,
                            year: this.interviewsDto.year,
                            type: this.interviewsDto.type,
                            subjectId: this.interviewsDto.subjectId,
                            tagIds: this.interviewsDto.tagIds
                        });
                    }
                }
            });
        },
        goToAddQuestion() {
            this.$router.push(
                `/questions/0/set/0/${this.interviewQuestionList.id}/${this.interviewQuestionList.subjectId}`
            );
        },
        open() {
            this.$refs.bankDialog.open();
        },
        search(query) {
            if (!this.id) {
                this.$store.commit("Set_Search_Dto", {
                    keys: ["name", "subjectName"],
                    query
                });
            } else {
                this.$store.commit("Set_Search_Dto", {
                    keys: ["title", "hint"],
                    query
                });
            }
        }
    }
};
</script>
